import React, { useContext, useEffect, useState } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";
import { PRINT_TYPES } from "../../../../data/constants";

const FEATURE_ID = "updateTransferOrders";
const APP_ID = "inventory";

const FORM_SECTIONS = "formSections";
const IS_PRINT_ENABLED = "isPrintEnabled";
const PRINT_TYPE = "printType";
const PALLET_TAG_PREFIX = "palletTagPrefix";
const TITLE = "title";

const UpdateTransferOrdersFeatureConfigContext = React.createContext({});

const initialState = {
  formSections: [],
  printType: PRINT_TYPES.ZPL,
  palletTagPrefix: "",
  title: "Update Transfer Order"
};

const formSectionIdMapping = {
  print_quantity: "item_quantity",
  tags_per_item: "print_quantity"
}

export const useUpdateTransferOrderFeatureConfigContext = () => {
  return useContext(UpdateTransferOrdersFeatureConfigContext);
};

export const UpdateTransferOrderFeatureConfigContextProvider = ({ children }) => {
  const configProvider = useAppConfigProvider(APP_ID);

  const [formSections, setFormSections] = useState(initialState.formSections);
  const [isPrintEnabled, setIsPrintEnabled] = useState(initialState.isPrintEnabled);
  const [printType, setPrintType] = useState(initialState.printType);
  const [palletTagPrefix, setPalletTagPrefix] = useState(initialState.palletTagPrefix);
  const [title, setTitle] = useState(initialState.title);

  useEffect(() => {
    const updateTransferOrdersConfiguration = configProvider.getValue(FEATURE_ID, "object");

    const newFormSections = updateTransferOrdersConfiguration[FORM_SECTIONS] || initialState.formSections;
    const newIsPrintEnabled = updateTransferOrdersConfiguration[IS_PRINT_ENABLED] || initialState.isPrintEnabled;
    const newPrintType = updateTransferOrdersConfiguration[PRINT_TYPE] || initialState.printType;
    const newPalletTagPrefix = updateTransferOrdersConfiguration[PALLET_TAG_PREFIX] || initialState.palletTagPrefix;
    const newTitle = updateTransferOrdersConfiguration[TITLE] || initialState.title;

    const processedFormSections = newFormSections.map((section) => {
      return section.map((field) => {
        return formSectionIdMapping[field.id] ? { ...field, id: formSectionIdMapping[field.id] } : field
      })
    });

    setFormSections(processedFormSections);
    setIsPrintEnabled(newIsPrintEnabled);
    setPrintType(newPrintType);
    setPalletTagPrefix(newPalletTagPrefix);
    setTitle(newTitle);
  }, [configProvider]);

  return (
    <UpdateTransferOrdersFeatureConfigContext.Provider
      value={{
        formSections,
        isPrintEnabled,
        printType,
        palletTagPrefix,
        title
      }}
    >
      {children}
    </UpdateTransferOrdersFeatureConfigContext.Provider>
  );
};
