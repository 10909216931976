import React, { useEffect, useState, useRef, useMemo } from "react";
import _ from "lodash";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import { ReactComponent as InventoryIcon } from "../../../../assets/icons/inventory.svg";
import BulkCreateTabFeature from "./features/bulk-create-tab-feature";
import {
  useAddInventoryFeatureV2StateContext,
  AddInventoryFeatureV2StateContextProvider
} from "./contexts/add-inventory-feature-v2-state-context/AddInventoryFeatureV2StateContext";
import useInventoryFormDataParser from "./hooks/use-inventory-form-data-parser";
import {
  AddInventoryFeatureV2ConfigContextProvider,
  useAddInventoryFeatureV2ConfigContext
} from "./contexts/add-inventory-feature-v2-config-context/AddInventoryFeatureV2ConfigContext";
import { PRINT_TIMES } from "../../../../data/constants";
import TabBar from "../../../../components/tab-bar";
import useMixpanelContext from "../../../../context/mixpanel-context";
import {
  INVENTORY_ONBOARDING_V2,
  INVENTORY_ONBOARDING_V2_STEPS
} from "../../../../constants/mixpanel-constant/inventoryOnboardingV2";
import usePrintService from "../../../../hooks/use-print-service";
import InventorySingleCreateTab from "./features/inventory-single-create-tab";
import OnboardItemModal from "../../../../components/onboard-item-modal";
import StatusPopupComponent from "../../../../components/status-popup-component";
import Style from "./AddInventoryV2Style.module.css";
import useOnboardingForm from "../../../../hooks/use-onboarding-form";
import PrinterSelectionComponent from "../../../../components/printer-selection-component";
import { StationSection } from "./features/inventory-single-create-tab/features/station-section/StationSection";
import { STATUS_OPTIONS } from "../../../../components/status-popup-component/data/constants";
import { Checkbox } from "../../../../components/checkbox-component/Checkbox";

const TAB_OPTIONS = {
  SINGLE: "single",
  BULK: "bulk"
};
const BATCH_SIZE = 50;
const POPUP_DURATION_MS = 5000;
const FEATURE_ID = "Inventory";

const AddInventoryPageFeatureV2 = ({ onClose }) => {
  const [inventoryClient] = useState(useXemelgoClient().getInventoryClient());

  const { sendMixPanelEvent } = useMixpanelContext();
  const [statusMessage, setStatusMessage] = useState("");
  const [popupExpired, setPopupExpired] = useState(false);

  const popupTimeoutRef = useRef(null);

  const {
    isLoading: isConfigLoading,
    customTemplate,
    printOnly,
    bartenderConfig,
    isBulkCreateEnabled,
    isPrintEnabled,
    printType,
    onboardingOptions,
    isPrintLaterEnabled,
    showSaveEntriesCheckbox,
    isPrintDuplicateTagEnabled,
    defaultPrintDuplicateTagQuantity
  } = useAddInventoryFeatureV2ConfigContext();

  const { endpoint: bartenderUrl } = bartenderConfig;
  const {
    formDataMap,
    setFormDataMap,
    submitStatus,
    setSubmitStatus,
    isSubmitDisabled,
    setIsSubmitDisabled,
    currentTab,
    setCurrentTab,
    formFields,
    selectedOnboardingMode,
    setSelectedOnboardingMode,
    isSaveLastSubmittedEntriesChecked,
    setIsSaveLastSubmittedEntriesChecked
  } = useAddInventoryFeatureV2StateContext();
  const printService = usePrintService(
    isPrintEnabled,
    printType,
    bartenderUrl ? bartenderConfig : customTemplate,
    FEATURE_ID,
    defaultPrintDuplicateTagQuantity
  );
  const customPrintConfig = { ...(bartenderUrl ? bartenderConfig : customTemplate && { customTemplate }) };
  const { selectedTemplateConfig, isPrintReady, printTime, printItems } = printService;
  const { generatePayload } = useInventoryFormDataParser();
  const { validateFormDataForSubmit } = useOnboardingForm();

  const tabList = useMemo(() => {
    const tempTabList = [
      {
        id: TAB_OPTIONS.SINGLE,
        label: "Create an Item"
      }
    ];
    if (isBulkCreateEnabled) {
      tempTabList.push({
        id: TAB_OPTIONS.BULK,
        label: "Bulk Create"
      });
    }
    return tempTabList;
  });

  const printTags = async (payloads, formData) => {
    // get item type numbers and query for additional item type attributes
    const itemTypesInfoMap = formData.reduce((newItemTypeInfoMap, eachForm) => {
      const { item_number: itemNumber } = eachForm;
      const { value } = itemNumber;
      newItemTypeInfoMap[value] = { ...itemNumber };
      return newItemTypeInfoMap;
    }, {});

    const newPayloads = payloads.map((payload) => {
      const { item_number: itemNumber, description } = payload;
      return {
        ...payload,
        ...itemTypesInfoMap[itemNumber],
        item_description: description
      };
    });

    await printItems(newPayloads);
  };

  const showSaveLastEntriesByCurrentTab = useMemo(() => {
    return showSaveEntriesCheckbox && currentTab === TAB_OPTIONS.SINGLE;
  }, [showSaveEntriesCheckbox, currentTab]);

  const clearFormInputs = () => {};

  const onToggleCheckbox = () => {
    setIsSaveLastSubmittedEntriesChecked(!isSaveLastSubmittedEntriesChecked);
  };

  const onSubmitV2 = async () => {
    setIsSubmitDisabled(true);
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage("The items are being added...");
    setPopupExpired(false);

    // setLoading(true);
    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }

    const promises = [];

    try {
      // ------------------ CREATE ------------------ //
      const formData = Object.values(formDataMap).map((eachForm) => {
        return eachForm.data;
      });
      const payloadsByLocations = await generatePayload(formData);
      const printPayloads = payloadsByLocations.reduce((newPayloads, payloadsByLocation) => {
        const { payloads } = payloadsByLocation;
        newPayloads.push(...payloads);
        return newPayloads;
      }, []);
      // create and onboard
      if (!printOnly) {
        payloadsByLocations.forEach((payloadsByLocation) => {
          const { payloads, locationId } = payloadsByLocation;
          const createItemPayloads = payloads.map((payload) => {
            const { print_quantity, ...rest } = payload;
            return rest;
          });

          while (createItemPayloads.length) {
            const currentSlice = createItemPayloads.splice(0, BATCH_SIZE);
            promises.push(inventoryClient.createItemSet(currentSlice, locationId));
          }
        });
        await Promise.all(promises);

        setStatusMessage("The items have been added successfully.");
      }

      // ------------------ PRINT ------------------ //
      if (!isPrintEnabled || printTime === PRINT_TIMES.later.id) {
        setSubmitStatus(STATUS_OPTIONS.SUCCESS);
        return;
      }
      setStatusMessage("The items have been added and are being printed...");

      await printTags(printPayloads, formData);

      setStatusMessage("The items have been added and submitted for printing.");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.includes("[BadRequest] vid")) {
        setStatusMessage("Failed to create item(s). RFID tag number already exists.");
      } else {
        setStatusMessage("Failed to create item(s). Please contact Xemelgo Support for assistance");
      }
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      sendMixPanelEvent(
        INVENTORY_ONBOARDING_V2,
        currentTab === TAB_OPTIONS.BULK
          ? INVENTORY_ONBOARDING_V2_STEPS.BULK_CREATE_FAILED
          : INVENTORY_ONBOARDING_V2_STEPS.ITEM_ONBOARD_FAILED,
        {
          error_message: typeof err === "string" ? err : err.message
        }
      );

      popupTimeoutRef.current = setTimeout(() => {
        setPopupExpired(true);
      }, POPUP_DURATION_MS);
      return;
    }

    sendMixPanelEvent(
      INVENTORY_ONBOARDING_V2,
      currentTab === TAB_OPTIONS.BULK
        ? INVENTORY_ONBOARDING_V2_STEPS.BULK_CREATE_SUCCESS
        : INVENTORY_ONBOARDING_V2_STEPS.ITEM_ONBOARD_SUCCESS,
      {
        item_onboarded_count: Object.values(formDataMap).length
      }
    );
    popupTimeoutRef.current = setTimeout(() => {
      setPopupExpired(true);
    }, POPUP_DURATION_MS);
    setSubmitStatus(STATUS_OPTIONS.SUCCESS);
  };

  useEffect(() => {
    sendMixPanelEvent(
      INVENTORY_ONBOARDING_V2,
      currentTab === TAB_OPTIONS.BULK
        ? INVENTORY_ONBOARDING_V2_STEPS.BULK_CREATE_ENTRY
        : INVENTORY_ONBOARDING_V2_STEPS.ENTRY
    );
  }, [currentTab]);

  useEffect(() => {
    return () => {
      if (popupTimeoutRef.current) {
        clearTimeout(popupTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isConfigLoading) {
      return;
    }

    return () => {
      clearFormInputs();
    };
  }, [isConfigLoading]);

  useEffect(() => {
    const formData = Object.values(formDataMap).map((eachForm) => {
      return eachForm.data;
    });
    const isFormReady = validateFormDataForSubmit(formData, formFields);
    setIsSubmitDisabled((isPrintEnabled && !isPrintReady) || !isFormReady);
  }, [formDataMap, currentTab, formFields, selectedTemplateConfig, isPrintReady]);

  return (
    <OnboardItemModal
      title="Add Items"
      titleIconComponent={
        <div className={Style.title_icon}>
          <InventoryIcon
            width={25}
            height={25}
          />
        </div>
      }
      popupComponent={
        <StatusPopupComponent
          showPopup={!popupExpired && submitStatus !== STATUS_OPTIONS.NONE && statusMessage}
          message={statusMessage}
          status={submitStatus}
        />
      }
      footerLeftComponent={
        showSaveLastEntriesByCurrentTab && (
          <div
            className={Style.save_checkbox_container}
            onClick={onToggleCheckbox}
          >
            <Checkbox isChecked={isSaveLastSubmittedEntriesChecked} />
            <p className={Style.save_label}>Remember item information</p>
          </div>
        )
      }
      onSubmit={onSubmitV2}
      onClose={onClose}
      isPrintEnabled={isPrintEnabled && printTime === PRINT_TIMES.now.id}
      submitDisabled={isSubmitDisabled}
      modalContainerClassName={Style.modal_container}
    >
      <div className={Style.modal_body_container}>
        {isBulkCreateEnabled && (
          <TabBar
            tabList={tabList}
            currentTabId={currentTab}
            onTabClick={(newTab) => {
              setCurrentTab(newTab);
              clearFormInputs();
              setFormDataMap({});
            }}
          />
        )}
        <div className={Style.tab_container}>
          {currentTab === TAB_OPTIONS.SINGLE ? <InventorySingleCreateTab /> : <BulkCreateTabFeature />}
          {onboardingOptions.length > 1 && currentTab === TAB_OPTIONS.SINGLE && (
            <TabBar
              tabList={onboardingOptions}
              currentTabId={selectedOnboardingMode}
              onTabClick={(newTab) => {
                setSelectedOnboardingMode(newTab);
              }}
              containerClassName={Style.tab_bar}
              labelClassName={Style.tab_label}
            />
          )}
          {selectedOnboardingMode === "print" ? (
            isPrintEnabled && (
              <div className={Style.printer_component}>
                <PrinterSelectionComponent
                  hideLabelSelection={!!Object.keys(customPrintConfig).length}
                  printService={printService}
                  solutionType={FEATURE_ID}
                  isPrintLaterEnabled={isPrintLaterEnabled}
                  isPrintQuantityEnabled={isPrintDuplicateTagEnabled}
                />
              </div>
            )
          ) : (
            <StationSection />
          )}
        </div>
      </div>
    </OnboardItemModal>
  );
};

export default (props) => {
  return (
    <AddInventoryFeatureV2ConfigContextProvider>
      <AddInventoryFeatureV2StateContextProvider>
        <AddInventoryPageFeatureV2 {...props} />
      </AddInventoryFeatureV2StateContextProvider>
    </AddInventoryFeatureV2ConfigContextProvider>
  );
};
